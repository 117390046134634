import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import DetailContent, { originalFields } from "./components/DetailContent";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  isDecimal,
  isVendor,
  printFromConnectedDevice,
  userDetails,
} from "@ui/Utils/helper";
import {
  chunkImagesUpload,
  createRecordWithHook,
  getByCode,
  getSingleRecord,
  search,
  updateRecordWithHook,
  updateRecord,
  getPaginatedData,
} from "@ui/views/data-engine/Import/api";

const entitySchema = {
  purity: JSON.parse(
    window.sessionStorage.getItem(
      enums.sessionStorage.recentlySelectedPurity
    ) || null
  ),
  color: JSON.parse(
    window.sessionStorage.getItem(enums.sessionStorage.recentSelectedColor) ||
      null
  ),
  lessWeight: 0,
  grossWeight: 0,
  netWeight: 0,
  frontEnd: {},
};

const ProductDetail = () => {
  const validationSchema = yup.object().shape({
    category: yup
      .object()
      .shape({
        name: yup.string().nullable().required("required"),
      })
      .nullable()
      .required("required"),

    grossWeight: yup
      .number()
      .moreThan(0, "Invalid Gross Weight")
      .typeError("Gross Weight must be a number")
      .required("required"),
    lessWeight: yup
      .number()
      .min(0, "Invalid Less Weight")
      .typeError("Less Weight must be a number"),
    netWeight: yup
      .number()
      .moreThan(0, "Invalid Net Weight")
      .typeError("Net Weight must be a number")
      .required("required"),
    purity: yup
      .object()
      .nullable()
      .shape({
        value: yup.number().nullable().required("Purity Value is required"),
        percent: yup.number().nullable(),
      })
      .test("has-value", "Purity value is required", function (value) {
        return value && value.value !== undefined && value.value !== null;
      })
      .required("Purity is required"),

    totalExtrasPrice: yup
      .number()
      .min(0, "Invalid Total Extras Price")
      .typeError("must be a number"),
    qty: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        !originalValue?.toString().trim() ? null : value
      )
      .integer("Pieces must be a whole number")
      .optional()
      .typeError("Pieces must be a number"),

    color: yup.string().nullable().required("Color is required"),
    vendorCode: isVendor
      ? yup.string().nullable().required("Required*")
      : yup.string().nullable(),
  });

  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const { state } = useLocation();

  const [configuration, setConfiguration] = useState({});
  const [originalConfiguration, setOriginalConfiguration] = useState({
    defaultStockStatus: enums.stockStatus.staged,
    fields: originalFields,
  });
  const [originalStockData, setOriginalStockData] = useState({
    packagingHistory: [], // currently used for this field in stockStatus onChange
    stockStatus: "",
  });
  const [openDiamondDetails, setOpenDiamondDetails] = useState(false);
  const [metalPricing, setMetalPricing] = useState({});
  const [defaultExtrasPrice, setDefaultExtrasPrice] = useState({});
  const [signDefaultImage, setSignDefaultImage] = useState(null);
  const params = useParams();
  const [openConfiguration, setOpenConfiguration] = useState(false);

  // const auth = useAuth();
  const [isEditable, setIsEditable] = useState(false);
  const model = enums.models["data-engine"].products;
  // const values = formMethods.watch();
  const [isCloned, setIsCloned] = useState(false); // used only for reset button in DC.js till, 23 Feb, 2024
  const [purities, setPurities] = useState(); // not initialising to empty array for an if condition to not show Purities required in UI before its API loads

  useEffect(() => {
    (async () => {
      try {
        setPurities(
          (await search(enums.models["data-engine"].purity, {
            filterColumns: { value: 1, percent: 1, _id: 1 },
          })) || []
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    const id = params?.code;

    (async () => {
      try {
        // Create an array of promises for all API calls
        const promises = [
          getSingleRecord(enums.models["data-engine"].configuration),
          isVendor
            ? {}
            : getSingleRecord(enums.models["data-engine"].goldPricing, {
                filterColumns: { priceLessConfigs: 1 },
              }),
          id
            ? getByCode(enums.models["data-engine"].products, id)
            : Promise.resolve(null), // Resolve to null if id is not present
        ];

        // Execute all promises concurrently
        const [configurationRes, metalPricingRes, productRes] =
          await Promise.all(promises);

        // Handle the configuration response
        if (!configurationRes?._id) {
          setConfiguration({
            defaultStockStatus: enums.stockStatus.staged,
            fields: originalFields,
          });
          setOpenConfiguration(true);
        } else {
          setConfiguration(configurationRes);
          setOriginalConfiguration(configurationRes);
        }

        setMetalPricing(metalPricingRes);

        let defaultExtrasPriceValue = {};
        Object.keys(
          metalPricingRes?.priceLessConfigs?.[0]?.priceField || {}
        ).map(
          (priceFieldName) =>
            (defaultExtrasPriceValue[priceFieldName] =
              metalPricingRes.priceLessConfigs[0].priceField[priceFieldName]
                ?.customPrice || 0)
        );
        setDefaultExtrasPrice(defaultExtrasPriceValue);

        const validStockStatus = isVendor
          ? enums.stockStatus.staged
          : configuration?.defaultStockStatus;

        if (id) {
          formMethods.reset({
            ...productRes,
          });
          setOriginalStockData({
            packagingHistory: productRes?.packagingHistory || [],
            stockStatus: productRes?.stockStatus,
          }); // to use in onChange of stockStatus input field
          setIsEditable(false);
        } else {
          if (state?.formData) {
            if (state.isCloned) setIsCloned(true);
            formMethods.reset({
              ...state.formData,
              stockStatus: validStockStatus,
            });
          } else {
            formMethods.reset({
              ...entitySchema,
              stockStatus: validStockStatus,
            });
          }
          setOriginalStockData({
            packagingHistory: [], // as cloned record will get removed with packagingHistory
            stockStatus: validStockStatus,
          });
          setIsEditable(true);
        }
      } catch (err) {
        console.log(err);
        setOpenConfiguration(true);
        navigate(`/${model}`);
      }
    })();
  }, [params?.code, state]);

  const onSubmit = async (values) => {
    try {
      delete values.additionalMedia;

      let res = {},
        action = "Added to Inventory",
        updatedResponse; // don't initialize anything to updatedResponse

      values.configuration = configuration;

      delete values.catalogues;

      // Config validation
      let invalidFieldsFlag = false;

      (configuration.fields || []).map((field) => {
        if (
          ((!isNaN(values[field.name]) && Number(values[field.name]) < 0) || // negative number not allowed in any field
            ((field.name === "qty" || field.name.match("Pieces")) &&
              values[field.name] &&
              isDecimal(Number(values[field.name])))) && // decimal values not allowed for Pieces fields now
          !(
            field.label === enums.stockLevelField.customField ||
            field.label === enums.stockLevelField.customField1 ||
            field.label === enums.stockLevelField.customField2 ||
            field.label === enums.stockLevelField.customField3 ||
            field.label === enums.stockLevelField.customField4
          )
        ) {
          invalidFieldsFlag = true;

          return CustomEventEmitter.emit(
            "alert_error",
            `Invalid ${field.label}`
          );
        }
      });

      if (invalidFieldsFlag) return;
      if (Number(values.netWeight) <= 0) {
        return CustomEventEmitter.emit("alert_error", "Invalid Net Weight");
      }
      if (
        (!configuration.autoSKU && !values.sku) ||
        (values.code && !values.sku)
      ) {
        return CustomEventEmitter.emit("alert_error", "Fill SKU to continue");
      }
      if (
        (!configuration.autoStyleNo && !values.styleNo) ||
        (values.code && !values.styleNo)
      ) {
        return CustomEventEmitter.emit(
          "alert_error",
          "Fill Design No to continue"
        );
      }

      if (
        values.frontEnd?.newPrint &&
        !values.frontEnd?.printerTemplate?.tsplCommands?.trim()
      ) {
        return CustomEventEmitter.emit("alert_error", "Invalid Printemplate");
      }

      values.stockStatus =
        values.stockStatus ||
        configuration.defaultStockStatus ||
        enums.stockStatus.staged;

      if (!values.code) {
        res = await createRecordWithHook(model, values);

        // formMethods.reset({ ...entitySchema });
      } else {
        res = await updateRecordWithHook(model, values);
        action = "Updated";
        updatedResponse = res;
        // formMethods.reset(res);
        setIsEditable(false);
      }

      if (values.frontEnd?.newImage) {
        const file = values.defaultImage;
        var blob = file.slice(0, file.size, "image/png");
        const newFile = new File([blob], `${res.styleNo}.png`, {
          type: "image/png",
        });

        try {
          const imageUploadRes = await chunkImagesUpload([newFile]);
          CustomEventEmitter.emit("alert_success", "Image Uploaded");
          setSignDefaultImage(!signDefaultImage);
          if (
            imageUploadRes.uploadedFiles.some((file) =>
              file.hasOwnProperty("cortexRun")
            )
          ) {
            if (imageUploadRes.uploadedFiles[0].cortexRun) {
              CustomEventEmitter.emit("alert_success", "AI stats confirmed");
            } else {
              CustomEventEmitter.emit(
                "alert_error",
                imageUploadRes.uploadedFiles[0].cortexErrorMessage
                  ? `AI stats: ${imageUploadRes.uploadedFiles[0].cortexErrorMessage}`
                  : "AI stats failed for this Image"
              );
            }
          }
        } catch (err) {
          console.log(err);
          CustomEventEmitter.emit("alert_error", "Image failed to upload");
        }
      }

      CustomEventEmitter.emit("alert_success", "Successfully " + action);

      if (values.frontEnd?.newPrint) {
        const stock = (
          await getPaginatedData(enums.models["data-engine"].products, {
            criteria: [{ _id: res._id }, {}],
          })
        )?.records?.[0];

        const printCommand = eval(
          values.frontEnd?.printerTemplate?.tsplCommands
        );

        try {
          await printFromConnectedDevice(
            values.frontEnd.newPrint,
            printCommand
          );

          updatedResponse = await updateRecord(
            enums.models["data-engine"].products,
            {
              code: stock.code,
              printHistory: [
                ...(stock.printHistory || []),
                {
                  dateCreated: new Date(),
                  createdBy: {
                    _id: userDetails._id,
                    firstName: userDetails.firstName,
                    lastName: userDetails.lastName,
                    role: userDetails.role,
                    vendorCode: userDetails.vendorCode,
                  },
                },
              ],
            }
          );

          window.location.reload();

          CustomEventEmitter.emit("alert_success", "Printed Successfully");
        } catch (err) {
          console.log(err);
        }
      } else {
        window.location.reload();
      }

      // if (updatedResponse) {
      //   formMethods.reset({ ...updatedResponse });
      // } else {
      //   formMethods.reset({ ...entitySchema });
      // }
    } catch (err) {
      console.log(err);
      CustomEventEmitter.emit(
        "alert_error",
        err?.response?.data?.message?.match("duplicate")
          ? "Duplicate SKU"
          : err.response?.data?.message || JSON.stringify(err)
      );
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        configuration={configuration}
        openDiamondDetails={openDiamondDetails}
        purities={purities}
        setOpenDiamondDetails={setOpenDiamondDetails}
        openConfiguration={openConfiguration}
        setConfiguration={setConfiguration}
        setOpenConfiguration={setOpenConfiguration}
        setOriginalConfiguration={setOriginalConfiguration}
        originalConfiguration={originalConfiguration}
        metalPricing={metalPricing}
        defaultExtrasPrice={defaultExtrasPrice}
        signDefaultImage={signDefaultImage} // very bad implementation, fix this fix soon
        originalStockData={originalStockData}
      />

      <DetailContent
        openDiamondDetails={openDiamondDetails}
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
        configuration={configuration}
        openConfiguration={openConfiguration}
        purities={purities}
        isCloned={isCloned}
        defaultExtrasPrice={defaultExtrasPrice}
      />
    </FormProvider>
  );
};

export default ProductDetail;
