import React, { useEffect, useState } from "react";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import {
  createRecordWithHook,
  getSingleRecord,
  search,
  signStoredObjects,
  updateRecord,
  updateRecordWithHook,
} from "../../../api";
import _ from "lodash";
import { createFilterOptions } from "@mui/material/Autocomplete";
import imageIcon from "@ui/assets/data-engine/assets/ComponentIcons/Camera.png";
import { Grid } from "@material-ui/core";
import RHDocUpload from "@ui/ReactHookFormControls/RHDocUpload";
import { Paper } from "@material-ui/core";
import RHAsyncAutoComplete from "@ui/ReactHookFormControls/RHAsyncAutoComplete";
import Label from "@ui/components/Label";
import {
  camelCaseToReadable,
  getNestedObjValue,
  isAdmin,
  isSuperAdmin,
  isVendor,
  roundOff,
} from "@ui/Utils/helper";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import DiamondDetails from "./DiamondDetails";
import { originalFields } from "./DetailContent";
import Configuration from "./Configuration";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import { Tooltip } from "@material-ui/core/index";
import KeyIcon from "@mui/icons-material/Key";
import LockIcon from "@mui/icons-material/Lock";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import settingsIcon from "@ui/assets/data-engine/assets/ComponentIcons/settings.png";
import { IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import commonConfig from "config/commonConfig";
import Decimal from "decimal.js";

const filter = createFilterOptions();

const DetailHeader = ({
  isEditable,
  metalPricing,
  openDiamondDetails,
  configuration,
  setOpenDiamondDetails,
  openConfiguration,
  setConfiguration,
  setOpenConfiguration,
  purities,
  setOriginalConfiguration,
  originalConfiguration,
  defaultExtrasPrice,
  signDefaultImage,
  originalStockData,
}) => {
  const formMethods = useFormContext();
  const { setValue, getValues } = formMethods;
  const [selectedDesignCode, setSelectedDesignCode] = useState("");
  const [diamondPricingList, setDiamondPricingList] = useState([]);
  const [totalDiamondPrice, setTotalDiamondPrice] = useState(0);
  const values = getValues();

  useEffect(() => {
    if (!isEditable && values.styleNo) {
      (async () => {
        try {
          const res = await getSingleRecord(
            enums.models["data-engine"].additionalMedia,
            {
              styleNo: values.styleNo,
            }
          );

          setValue("additionalMedia.media", res?.media || []);
          setValue("additionalMedia.code", res?.code || "");
        } catch (error) {}
      })();
    }
  }, [values.styleNo, isEditable]);

  useEffect(() => {
    (async () => {
      try {
        setDiamondPricingList(
          await search(enums.models["data-engine"].diamondPricing)
        );
      } catch (error) {}
    })();
  }, []);

  // const handleCancel = () => {
  //   if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
  //     if (params?.code === "create") {
  //       navigate("/" + model);
  //     } else {
  //       formMethods.reset();
  //       setIsEditable(false);
  //     }
  //   }
  // };

  const handleConfigSubmit = async () => {
    try {
      let res = configuration,
        invalidFlag = false,
        missingFields = [];

      (configuration.fields || []).map((field) => {
        if (field.checked) {
          const nakedFieldName = field.name.replace("Rate", "");

          if (field.priceField) {
            if (field.pricePerWeight) {
              if (
                !configuration.fields.find(
                  (cF) => cF.name === nakedFieldName + "Weight" && cF.checked
                )
              ) {
                invalidFlag = true;
                missingFields.push(
                  camelCaseToReadable(nakedFieldName + "Weight")
                );
              }
            } else if (field.pricePerPiece) {
              if (
                !configuration.fields.find(
                  (cF) => cF.name === nakedFieldName + "Pieces" && cF.checked
                )
              ) {
                invalidFlag = true;
                missingFields.push(
                  camelCaseToReadable(nakedFieldName + "Pieces")
                );
              }
            }
          }
        }
      });

      if (invalidFlag && missingFields.length) {
        return window.alert(
          "Select fields to continue: " + missingFields.join(", ")
        );
      }

      if (invalidFlag) return;

      if (
        window.confirm(
          "Are you sure to save changes? This configuration will take effect for created or updated records from this moment."
        )
      ) {
        if (configuration.code) {
          res = await updateRecordWithHook(
            enums.models["data-engine"].configuration,
            configuration,
            {},
            "upsertMetalPricing=true"
          );
        } else {
          res = await createRecordWithHook(
            enums.models["data-engine"].configuration,
            configuration,
            {},
            "upsertMetalPricing=true"
          );
        }
        setOpenConfiguration(false);
        setConfiguration(res);
        setOriginalConfiguration(res);
        CustomEventEmitter.emit("alert_success", "Default Configuration SET");
      }
    } catch (err) {
      console.log(err);
      CustomEventEmitter.emit(
        "alert_error",
        err?.response?.data?.message?.match("duplicate")
          ? "Duplicate SKU"
          : err.response?.data?.message || JSON.stringify(err)
      );
    }
  };

  const handleUnlockStock = async () => {
    try {
      const customerId = values.lockedFrom.orderPro;

      const user = await getSingleRecord(enums.models["data-engine"].users, {
        _id: customerId,
        filterColumns: "firstName lastName phone role",
      });

      if (
        window.confirm(
          `Stock locked from ${user.role.toUpperCase()}: ${user.firstName} ${
            user.lastName
          } (${user.phone}). \n\nAre you sure to unlock ${values.sku}?`
        )
      ) {
        const res = await updateRecord(enums.models["data-engine"].products, {
          $unset: { "lockedFrom.orderPro": 1 },
          code: values.code,
        });

        formMethods.reset({ ...res });
        CustomEventEmitter.emit("alert_success", "Stock is now Available");
      }
    } catch (err) {
      console.log(err);
      CustomEventEmitter.emit(
        "alert_error",
        JSON.stringify(
          err?.response?.data?.message || err || "Something went wrong!"
        )
      );
    }
  };

  if (openDiamondDetails) {
    return (
      <DiamondDetails
        setOpenDiamondDetails={setOpenDiamondDetails}
        isEditable={isEditable}
      />
    );
  }

  if (openConfiguration) {
    return (
      <Grid container spacing={3} style={{ padding: "24px" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            style={{ textAlign: "center" }}
            onClick={() => {
              if (window.confirm("Are you sure to discard your changes?")) {
                setConfiguration(originalConfiguration);
                setOpenConfiguration(false);
              }
            }}
          >
            <Tooltip title={"Cancel"}>
              <IconButton style={{ color: "red" }}>
                <CancelIcon style={{ fontSize: "42px" }} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ textAlign: "center" }}
            onClick={handleConfigSubmit}
          >
            <Tooltip title={"Save and Return"}>
              <IconButton style={{ color: "green" }}>
                <SaveIcon style={{ fontSize: "42px" }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Configuration
            configuration={configuration}
            setConfiguration={setConfiguration}
            originalFields={originalFields}
            setValue={setValue}
            originalConfiguration={originalConfiguration}
            // isEditable={isEditable} // don't send isEditable coz, it only opens in editable mode.. <3
          />
        </Grid>
      </Grid>
    );
  }

  if (purities && !purities.length) {
    return (
      <Grid container spacing={3} style={{ padding: "24px" }}>
        Add Purities Master Data to Continue...
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      {/* <Header links={[]} pageTitle={"Products"} actions={actions} /> */}
      {!isEditable &&
      values.stockStatus === enums.stockStatus.inventory &&
      values.lockedFrom?.orderPro &&
      isAdmin ? (
        <Grid item xs={12} container justify="flex-end">
          <MyHoverIcons
            onClick={handleUnlockStock}
            muiIcon={<LockIcon style={{ fontSize: "34px" }} />}
            muiIconHover={
              <KeyIcon style={{ fontSize: "34px", color: "green" }} />
            }
            tooltip={"Unlock"}
          />
        </Grid>
      ) : null}

      <Grid item xs={12} sm={12} md={2} lg={2}>
        <DesignLogo
          isEditable={isEditable}
          selectedDesignCode={selectedDesignCode}
          signDefaultImage={signDefaultImage}
        />
      </Grid>
      <Grid item lg={10}>
        <PrimaryFields
          purities={purities}
          isEditable={isEditable}
          configuration={configuration}
          setSelectedDesignCode={setSelectedDesignCode}
          metalPricing={metalPricing}
          totalDiamondPrice={totalDiamondPrice}
          setTotalDiamondPrice={setTotalDiamondPrice}
          diamondPricingList={diamondPricingList}
          defaultExtrasPrice={defaultExtrasPrice}
          originalStockData={originalStockData}
        />
      </Grid>

      <Grid item container xs={12}>
        <Grid
          item
          lg={1}
          onClick={() => {
            if (
              (isAdmin || isSuperAdmin) &&
              window.confirm("Are you sure to edit your Configuration?")
            ) {
              setOpenConfiguration(true);
            }
          }}
        >
          {(isAdmin || isSuperAdmin) && ( //&& !isEditable
            <Grid style={{ cursor: "pointer" }} item xs={12}>
              <Tooltip title={"Edit Configuration"}>
                <img
                  src={settingsIcon}
                  alt="configuration"
                  style={{ height: 40, width: 40, marginLeft: "24px" }}
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sm={11}
          md={11}
          lg={11}
          container
          justify={"flex-end"}
        >
          {configuration.diamondFields || values.diamondDetails?.length ? (
            <div>
              <h1
                onClick={() => setOpenDiamondDetails(true)}
                style={{
                  // position: 'absolute',
                  width: "187px",
                  height: "25px",
                  // left: "1256px",
                  // top: "819px",

                  fontFamily: "Gothic A1",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "25px",
                  textAlign: "center",
                  color: "#0C0553",
                  cursor: "pointer",
                  // border: "1px solid #000000",
                  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                Diamond Details
              </h1>
            </div>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const PrimaryFields = ({
  isEditable,
  configuration,
  purities,
  setSelectedDesignCode,
  totalDiamondPrice,
  setTotalDiamondPrice,
  diamondPricingList,
  metalPricing,
  defaultExtrasPrice,
  originalStockData,
}) => {
  const formMethods = useFormContext();
  const { setValue, watch, formState } = formMethods;
  const values = watch();
  const [originalOptions, setOriginalOptions] = useState([]);

  useEffect(() => {
    Object.values(formState.errors || {}).map(
      (obj) =>
        obj.message && CustomEventEmitter.emit("alert_error", obj.message)
    );
  }, [formState.errors]);

  // EXTRAS PRICING
  useEffect(() => {
    let totalExtrasPrice = 0;
    (configuration?.fields || []).map((field) => {
      if (field.includeForTotalExtrasPrice) {
        const nakedFieldName = field.name.replace("Rate", "");
        const rawAmount = Number(
          !isNaN(parseFloat(values[field.name]))
            ? values[field.name] || 0
            : defaultExtrasPrice[field.name] || 0
        );

        if (field.pricePerPiece) {
          totalExtrasPrice +=
            Number(
              !isNaN(parseFloat(values[nakedFieldName + "Pieces"]))
                ? values[nakedFieldName + "Pieces"]
                : 0
            ) * rawAmount;
        } else if (field.pricePerWeight) {
          totalExtrasPrice +=
            Number(
              !isNaN(parseFloat(values[nakedFieldName + "Weight"]))
                ? values[nakedFieldName + "Weight"]
                : 0
            ) * rawAmount;
        } else {
          totalExtrasPrice += rawAmount;
        }
      }
    });

    setValue("totalExtrasPrice", Number(totalExtrasPrice.toFixed(2)));
  }, [
    configuration,

    // Weight fields
    values.customDiamondWeight,
    values.stoneWeight,
    values.pinWeight,
    values.meenaWeight,
    values.kundunWeight,
    values.motiWeight,
    values.moti2Weight,
    values.moti3Weight,
    values.chainWeight,
    values.colorStoneWeight,
    values.diamondDetails,
    values.beltWeight,
    values.moissaniteWeight,
    values.motherOfPearlWeight,
    values.dialWeight,
    values.woodWeight,
    values.fancyWeight,
    values.onyxWeight,
    values.blueCsWeight,
    values.blueKCsWeight,
    values.pinkCsWeight,
    values.greenCsWeight,
    values.flowerWeight,
    values.pearlWeight,
    values.blueLWeight,
    values.blue2Weight,
    values.blueTWeight,
    values.rubyWeight,
    values.pinkKWeight,
    values.pinkLWeight,
    values.pink2Weight,
    values.pinkTWeight,
    values.greenKWeight,
    values.green2Weight,
    values.greenLWeight,
    values.greenTWeight,
    values.redKWeight,
    values.redCsWeight,
    values.redLWeight,
    values.redTWeight,
    values.yellowKWeight,
    values.yellowCsWeight,
    values.yellowLWeight,
    values.yellowTWeight,
    values.navratnaStoneWeight,
    values.latkanWeight,
    values.latkanPieces,
    values.emeraldWeight,
    values.coralWeight,
    values.hessoniteGametWeight,
    values.blueSapphireWeight,
    values.catsEyeWeight,
    values.yellowSapphireWeight,
    values.blackBeadsWeight,
    values.palchaWeight,
    values.rudrakshaWeight,
    values.amethystSquareWeight,
    values.chalcedonyWeight,
    values.garnetMarquiseWeight,
    values.pinkBeadsWeight,
    values.emeraldBeadsWeight,
    values.rubyBeadsWeight,
    values.greenBeadsWeight,
    values.greenEmeraldWeight,
    values.pinkPearWeight,
    values.czSolitaireWeight,

    // Pieces Fields
    values.customDiamondPieces,
    values.stonePieces,
    values.colorStonePieces,
    values.motiPieces,
    values.moti2Pieces,
    values.moti3Pieces,
    values.chainPieces,
    values.kundunPieces,
    values.meenaPieces,
    values.pinPieces,
    values.beltPieces,
    values.moissanitePieces,
    values.motherOfPearlPieces,
    values.dialPieces,
    values.woodPieces,
    values.fancyPieces,
    values.onyxPieces,
    values.blueCsPieces,
    values.blueKCsPieces,
    values.greenCsPieces,
    values.pinkCsPieces,
    values.flowerPieces,
    values.pearlPieces,
    values.blueLPieces,
    values.blue2Pieces,
    values.blueTPieces,
    values.rubyPieces,
    values.pinkKPieces,
    values.pinkLPieces,
    values.pink2Pieces,
    values.pinkTPieces,
    values.greenKPieces,
    values.green2Pieces,
    values.greenLPieces,
    values.greenTPieces,
    values.redKPieces,
    values.redCsPieces,
    values.redLPieces,
    values.redTPieces,
    values.yellowKPieces,
    values.yellowCsPieces,
    values.yellowLPieces,
    values.yellowTPieces,
    values.navratnaStonePieces,
    values.latkanPieces,
    values.emeraldPieces,
    values.coralPieces,
    values.hessoniteGametPieces,
    values.blueSapphirePieces,
    values.catsEyePieces,
    values.yellowSapphirePieces,
    values.blackBeadsPieces,
    values.palchaPieces,
    values.rudrakshaPieces,
    values.amethystSquarePieces,
    values.chalcedonyPieces,
    values.garnetMarquisePieces,
    values.pinkBeadsPieces,
    values.emeraldBeadsPieces,
    values.rubyBeadsPieces,
    values.greenBeadsPieces,
    values.greenEmeraldPieces,
    values.pinkPearPieces,
    values.czSolitairePieces,

    // Price fields are back bee.. ;) @override it
    values.customDiamondRate,
    values.stoneRate,
    values.colorStoneRate,
    values.motiRate,
    values.moti2Rate,
    values.moti3Rate,
    values.chainRate,
    values.kundunRate,
    values.meenaRate,
    values.pinRate,
    values.beltRate,
    values.moissaniteRate,
    values.motherOfPearlRate,
    values.dialRate,
    values.woodRate,
    values.fancyRate,
    values.onyxRate,
    values.blueCsRate,
    values.blueKCsRate,
    values.greenCsRate,
    values.pinkCsRate,
    values.flowerRate,
    values.pearlRate,
    values.blueLRate,
    values.blue2Rate,
    values.blueTRate,
    values.rubyRate,
    values.pinkKRate,
    values.pinkLRate,
    values.pink2Rate,
    values.pinkTRate,
    values.greenKRate,
    values.green2Rate,
    values.greenLRate,
    values.greenTRate,
    values.redKRate,
    values.redCsRate,
    values.redLRate,
    values.redTRate,
    values.yellowKRate,
    values.yellowCsRate,
    values.yellowLRate,
    values.yellowTRate,
    values.navratnaStoneRate,
    values.latkanRate,
    values.emeraldRate,
    values.coralRate,
    values.hessoniteGametRate,
    values.blueSapphireRate,
    values.catsEyeRate,
    values.yellowSapphireRate,
    values.blackBeadsRate,
    values.palchaRate,
    values.rudrakshaRate,
    values.amethystSquareRate,
    values.chalcedonyRate,
    values.garnetMarquiseRate,
    values.pinkBeadsRate,
    values.emeraldBeadsRate,
    values.rubyBeadsRate,
    values.greenBeadsRate,
    values.greenEmeraldRate,
    values.pinkPearRate,
    values.czSolitaireRate,
  ]);

  // DIAMOND PRICING AND TOTAL WEIGHT

  useEffect(() => {
    const diamondDetailsPrices = []; // not used yet.. use it show each diamond price in ui if required... ;)

    let diamondPrice = 0;
    (values.diamondDetails || []).map((diamond) => {
      const matchedDiamondPrice = diamondPricingList.find((diamondPrice) => {
        const isShapeMatched =
            diamond.shape === "Round"
              ? diamondPrice.diamondType === "Round"
              : diamondPrice.diamondType === "Fancy",
          caratPerQuantity = Number(
            (
              (diamond.totalCarat || 0) / (diamond.diamondQuantity || 1)
            ).toFixed(3)
          );

        if (
          isShapeMatched &&
          caratPerQuantity >= diamondPrice.minCarat &&
          caratPerQuantity <= diamondPrice.maxCarat
        ) {
          return true;
        }
      });

      let currentDiamondPrice = 0;
      if (matchedDiamondPrice) {
        // else, default ratePerCarat is taken if they are not
        currentDiamondPrice =
          (matchedDiamondPrice.ratePerCarat || 0) * (diamond.totalCarat || 0);

        diamondPrice += currentDiamondPrice;
      }

      diamondDetailsPrices.push(Number(currentDiamondPrice.toFixed(2)));
    });

    setTotalDiamondPrice(diamondPrice);
  }, [values.diamondDetails, metalPricing]);

  useEffect(() => {
    // At the heart of these issues is JavaScript's adherence to the IEEE 754 standard for floating-point arithmetic. This standard, while providing a consistent approach for number representation across various programming languages, introduces several limitations when dealing with decimal numbers.
    // #heart <3

    let lessWeight = new Decimal(0);
    let totalDiamondWeight = new Decimal(0);

    // Process fields included for less weight
    (configuration?.fields || []).forEach((field) => {
      if (field.includeForLessWeight) {
        const fieldValue = new Decimal(values[field.name] || 0);

        if (field.inputInCts) {
          lessWeight = lessWeight.plus(fieldValue.div(5)); // Accurate division
        } else {
          lessWeight = lessWeight.plus(fieldValue); // Add directly
        }
      }
    });

    // Process diamond details
    (values.diamondDetails || []).forEach((diamond) => {
      const diamondWeight = new Decimal(diamond.totalCarat || 0);
      totalDiamondWeight = totalDiamondWeight.plus(diamondWeight); // Accurate addition
      lessWeight = lessWeight.plus(diamondWeight.div(5)); // Dividing by 5 to convert cts to g
    });

    // Calculate net weight
    const grossWeight = new Decimal(values.grossWeight || 0);
    const netWeight = grossWeight.minus(lessWeight); // Subtraction without floating-point errors

    // Set calculated values back
    setValue("lessWeight", lessWeight.toNumber());
    setValue("netWeight", netWeight.toNumber());
    setValue("totalDiamondWeight", totalDiamondWeight.toNumber());
  }, [
    configuration,

    // only weight fields
    values.grossWeight,
    values.customDiamondWeight,
    values.stoneWeight,
    values.pinWeight,
    values.meenaWeight,
    values.kundunWeight,
    values.motiWeight,
    values.moti2Weight,
    values.moti3Weight,
    values.chainWeight,
    values.colorStoneWeight,
    values.diamondDetails,
    values.beltWeight,
    values.moissaniteWeight,
    values.motherOfPearlWeight,
    values.dialWeight,
    values.woodWeight,
    values.fancyWeight,
    values.onyxWeight,
    values.blueCsWeight,
    values.blueKCsWeight,
    values.pinkCsWeight,
    values.greenCsWeight,
    values.flowerWeight,
    values.pearlWeight,
    values.blueLWeight,
    values.blue2Weight,
    values.blueTWeight,
    values.rubyWeight,
    values.pinkKWeight,
    values.pinkLWeight,
    values.pink2Weight,
    values.pinkTWeight,
    values.greenKWeight,
    values.green2Weight,
    values.greenLWeight,
    values.greenTWeight,
    values.redKWeight,
    values.redCsWeight,
    values.redLWeight,
    values.redTWeight,
    values.yellowKWeight,
    values.yellowCsWeight,
    values.yellowLWeight,
    values.yellowTWeight,
    values.navratnaStoneWeight,
    values.latkanWeight,
    values.emeraldWeight,
    values.coralWeight,
    values.hessoniteGametWeight,
    values.blueSapphireWeight,
    values.catsEyeWeight,
    values.yellowSapphireWeight,
    values.blackBeadsWeight,
    values.palchaWeight,
    values.rudrakshaWeight,
    values.amethystSquareWeight,
    values.chalcedonyWeight,
    values.garnetMarquiseWeight,
    values.pinkBeadsWeight,
    values.emeraldBeadsWeight,
    values.rubyBeadsWeight,
    values.greenBeadsWeight,
    values.greenEmeraldWeight,
    values.pinkPearWeight,
    values.czSolitaireWeight,
  ]);

  return (
    <Paper
      style={{
        padding: "24px",
        background: `rgba(255, 255, 255, 0.45)`,
        boxShadow: `0px 0.60977px 4.57327px rgba(0, 0, 0, 0.15)`,
        borderRadius: `8.08874px`,
      }}
    >
      <Grid container spacing={3}>
        <Grid
          item
          xs={12} // Full width on extra small screens
          sm={6} // 3 columns on small screens
          md={4} // 3 columns on medium screens
          lg={4} // 3 columns on large screens
        >
          {isEditable ? (
            <RHAsyncAutoComplete
              customBaseApiUrl={commonConfig.baseApi["data-engine"]}
              autoFocus={true}
              // dynamicAPIbody={true}
              name="styleNo"
              label="Design"
              filterOptionLabelKey="styleNo"
              setOriginalOptions={setOriginalOptions}
              freeSolo={true}
              onChange={(_, newValue) => {
                const existingDesign = originalOptions.find(
                  (opt) =>
                    opt?.styleNo?.toString().toLowerCase() ===
                    newValue?.toString().toLowerCase()
                );

                if (existingDesign) {
                  setValue("category", existingDesign.category);
                  setValue("subCategory", existingDesign.subCategory);
                  setValue("collectionLine", existingDesign.collectionLine);

                  setValue("imageFlag", !!existingDesign.imageFlag);

                  setSelectedDesignCode(existingDesign.code);
                } else {
                  setValue("imageFlag", false);
                  setValue("defaultImage", {});
                }

                setValue("styleNo", newValue);
              }}
              value={values.styleNo || ""}
              selectOnFocus={true}
              clearOnBlur={true}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option
                );

                if (inputValue !== "" && !isExisting) {
                  filtered.push(`${inputValue}`);
                }

                return filtered;
              }}
              apiUrl={
                "c/" + enums.models["data-engine"].products + "/uniqueDesigns"
              }
              apiBody={{
                filterColumns: {
                  styleNo: 1,
                  category: 1,
                  collectionLine: 1,
                  subCategory: 1,
                  imageFlag: 1,
                  code: 1,
                },
              }}
              apiMethod="post"
            />
          ) : (
            <Label label={"Design"} value={values.styleNo || ""} />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          {isEditable ? (
            <RHTextField name={"sku"} label={"SKU"} type={"text"} />
          ) : (
            <Label value={values.sku} label={"SKU"} />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          {isEditable ? (
            <RHAutoComplete
              name={"purity"}
              label={"Purity"}
              options={purities}
              getOptionSelected={(o, v) => o?._id === v?._id}
              disableClearable={true}
              getOptionLabel={(opt) => (opt.value ? opt.value + " KT" : null)}
              onChange={(_, val) =>
                window.sessionStorage.setItem(
                  enums.sessionStorage.recentlySelectedPurity,
                  JSON.stringify(val)
                )
              }
              /* 
              getOptionLabel={(opt) =>
                opt.value
                  ? opt.value + " KT (" + opt.percent * 100 + "%)"
                  : null
              }


              values.purity?.value
                  ? values.purity.value +
                    " KT (" +
                    values.purity.percent * 100 +
                    "%)"
                  : ""
              */
            />
          ) : (
            <Label
              value={values.purity?.value ? values.purity?.value + " KT" : ""}
              label={"Purity"}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <CategorySubCollectionLine
            isEditable={isEditable}
            name={"category"}
            label="Category"
            model={enums.models["data-engine"].category}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <CategorySubCollectionLine
            isEditable={isEditable}
            name={"subCategory"}
            label="Sub Category"
            model={enums.models["data-engine"].subCategory}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <CategorySubCollectionLine
            isEditable={isEditable}
            name={"collectionLine"}
            label="Collection Line"
            model={enums.models["data-engine"].collectionLines}
          />
        </Grid>
        {/* <Grid item xs={2}></Grid>/ */}
        <Grid item xs={12} sm={6} md={4} lg={4}>
          {isEditable ? (
            <RHTextField
              value={values.grossWeight}
              type={enums.dataTypes.number}
              label="Gross Weight (g)"
              InputProps={{ inputProps: { min: 0 } }}
              name="grossWeight"
            />
          ) : (
            <Label
              label="Gross Weight (g)"
              value={roundOff(values.grossWeight, { weight: true })}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Label
            labelStyle={{ color: enums.colorTheme.primary }}
            valueStyle={{ color: enums.colorTheme.primary }}
            label={`Less Weight (g)`}
            value={roundOff(values.lessWeight, { weight: true })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Label
            label="Net Weight (g)"
            value={roundOff(values.netWeight, { weight: true })}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          {isEditable ? (
            <RHAutoComplete
              name={"color"}
              label={"Color"}
              // disableClearable={true}
              options={Object.values(enums.colorOptions) || []}
              onChange={(_, val) => {
                window.sessionStorage.setItem(
                  enums.sessionStorage.recentSelectedColor,
                  JSON.stringify(val)
                );
              }}
            />
          ) : (
            <Label value={values?.color || ""} label={"Color"} />
          )}
        </Grid>

        {/* <Grid item xs={2}></Grid> */}

        <Grid item xs={12} sm={6} md={4} lg={4}>
          {(isAdmin || isSuperAdmin) &&
          isEditable &&
          (!values.stockStatus ||
            values.stockStatus === enums.stockStatus.staged ||
            values.stockStatus === enums.stockStatus.inventory) ? (
            <RHAutoComplete
              label="Stock Status"
              name="stockStatus"
              defaultValue={
                configuration?.defaultStockStatus
                  ? configuration?.defaultStockStatus
                  : null
              }
              disableClearable={true}
              options={[enums.stockStatus.staged, enums.stockStatus.inventory]}
              onChange={(_, value) => {
                if (values.code) {
                  const tempPackagingHistory = [
                    ...(originalStockData.packagingHistory || []),
                  ];

                  if (originalStockData.stockStatus !== value) {
                    tempPackagingHistory.push({
                      source: "Data Engine",
                      stockStatus: value,
                      action: "Data Entry",
                      dateCreated: new Date(),
                    });
                  }

                  setValue("packagingHistory", tempPackagingHistory);
                }
              }}
            />
          ) : (
            <Label label="Stock Status" value={values.stockStatus || ""} />
          )}
        </Grid>

        {isVendor ? null : (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            {(configuration.fields || []).find(
              (field) => field.checked && field.includeForTotalExtrasPrice
            ) ? (
              <Label
                valueStyle={{
                  color: enums.colorTheme.fouraryLight,
                }}
                labelStyle={{
                  color: enums.colorTheme.fouraryLight,
                }}
                label="Total Extras Price"
                value={
                  values.totalExtrasPrice
                    ? roundOff(values.totalExtrasPrice)
                    : "0"
                }
              />
            ) : null}
          </Grid>
        )}

        {configuration.diamondFields ? (
          <>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Label
                label="Total Diamond Weight (cts)"
                value={
                  values.totalDiamondWeight
                    ? roundOff(values.totalDiamondWeight, {
                        weight: true,
                        isDiamond: true,
                      })
                    : "0"
                }
              />
            </Grid>
            {isVendor ? null : (
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Label
                  label="Total Diamond Price"
                  value={totalDiamondPrice || "0"}
                />
              </Grid>
            )}
          </>
        ) : null}
        {/* <Grid item xs={12}
          sm={6} 
          md={4} 
          lg={4} >
          <CategorySubCollectionLine
            isEditable={isEditable}
            name={"collectionLine"}
            label="Collection Line"
            model={enums.models.collectionLines}
          />
        </Grid> */}
      </Grid>
    </Paper>
  );
};

const CategorySubCollectionLine = ({ isEditable, name, label, model }) => {
  const { watch } = useFormContext();
  const values = watch();
  // const [originalOptions, setOriginalOptions] = useState([]);

  return isEditable ? (
    <RHAsyncAutoComplete
      customBaseApiUrl={commonConfig.baseApi["data-engine"]}
      name={name}
      label={label}
      getOptionLabel={(option) => option?.name || ""}
      // freeSolo={true}
      // setOriginalOptions={setOriginalOptions}
      // onChange={(_, newValue) => {
      //   let value = {};
      //   const existing = originalOptions.find(
      //     (opt) =>
      //       opt?.name?.toString().toLowerCase() ===
      //       newValue?.toString().toLowerCase()
      //   );

      //   if (existing) {
      //     value = existing;
      //   } else if (typeof newValue === "string") {
      //     value = {
      //       name: newValue,
      //     };
      //   } else if (newValue && newValue.inputValue) {
      //     // Create a new value from the user input
      //     value = {
      //       name: newValue.inputValue,
      //     };
      //   } else {
      //     value = newValue;
      //   }

      //   setValue(name, value);
      // }}
      selectOnFocus={true}
      clearOnBlur={true}
      // filterOptions={(options, params) => {
      //   const filtered = filter(options, params);
      //   const { inputValue } = params;
      //   // Suggest the creation of a new value
      //   const isExisting = options.some((option) => inputValue === option.name);

      //   if (inputValue !== "" && !isExisting) {
      //     filtered.push({
      //       inputValue,
      //       name: `Add "${inputValue}"`,
      //     });
      //   }

      //   return filtered;
      // }}
      apiUrl={model + "/search"}
      apiBody={{
        filterColumns: {
          name: 1,
        },
      }}
      apiMethod="post"
      getOptionSelected={(o, v) => o?._id === v?._id}
    />
  ) : (
    <Label
      label={label}
      value={getNestedObjValue(values, `${name}.name`) || ""}
    />
  );
};

const DesignLogo = ({ isEditable, selectedDesignCode, signDefaultImage }) => {
  const { setValue, watch } = useFormContext();
  const values = watch();

  useEffect(() => {
    (async () => {
      try {
        if (selectedDesignCode) {
          const signedImage =
            ((await signStoredObjects(enums.models["data-engine"].products, {
              codes: [selectedDesignCode],
            })) || [])[0]?.defaultImage || {};

          setValue("defaultImage", signedImage);
        } else if (signDefaultImage !== null) {
          const signedImage =
            ((await signStoredObjects(enums.models["data-engine"].products, {
              codes: [values.code],
            })) || [])[0]?.defaultImage || {};

          setValue("defaultImage", signedImage);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [selectedDesignCode, signDefaultImage]);

  const onAdditionalMediaUpload = async () => {
    try {
      const additionalMedia = await getSingleRecord(
        enums.models["data-engine"].additionalMedia,
        { styleNo: values.styleNo }
      ); // fix this soon, api is not required after upload, it should be received from upload response it self

      setValue("additionalMedia", {
        media: additionalMedia.media,
        code: additionalMedia.code,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemoveUploadedDoc = async (
    uploadIndex,
    items,
    fieldOnChange,
    setOpenPreview
  ) => {
    // additional Media Remove
    try {
      let confirmResult = await window.confirm(
        "Delete Document! Are you sure?"
      );
      if (confirmResult && values.additionalMedia.code) {
        setOpenPreview(false);

        (items || []).splice(uploadIndex, 1);

        const res = await updateRecord(
          enums.models["data-engine"].additionalMedia,
          {
            code: values.additionalMedia.code,
            media: items,
          }
        );
        fieldOnChange(_.cloneDeep(res?.media) || []);

        CustomEventEmitter.emit("alert_success", "Deleted Successfully");
      } else if (confirmResult && !values.additionalMedia.code) {
        CustomEventEmitter.emit(
          "alert_success",
          "Something went wrong! Reload to retry"
        );
      }
    } catch (err) {
      CustomEventEmitter.emit("alert_error", "Something went wrong!");
    }
  };

  return (
    <Grid container>
      <Paper
        style={{
          padding: "24px",
          background: `rgba(255, 255, 255, 0.45)`,
          boxShadow: `0px 0.60977px 4.57327px rgba(0, 0, 0, 0.15)`,
          borderRadius: `8.08874px`,
        }}
      >
        <RHDocUpload
          previewStyle={{
            height: isEditable ? "140px" : "180px",
            width: "140px",
          }}
          disabled={!isEditable}
          dontUpload={true}
          name="defaultImage" // not saving here, just getting signedUrl in this key from backend for every product
          previewThumbnail={true}
          label={isEditable && "Default Photo"}
          single={true}
          fieldType="upload"
          accept="image/png, image/jpeg"
          replace={true}
          uploadIcon={{
            type: "png",
            alt: "Icon",
            icon: imageIcon,
            style: { height: 40, width: 40 },
          }}
          onUpload={() => setValue("frontEnd.newImage", true)}
        />

        {!isEditable && values.code ? (
          <RHDocUpload
            disabled={isEditable}
            name="additionalMedia.media"
            model={enums.models["data-engine"].additionalMedia}
            additionalPath={values.styleNo}
            label={"Additional Media"}
            hideUploadIcon={isEditable}
            isEditable={!isEditable}
            fieldType="upload"
            accept="image/*,video/*,image/gif"
            previewThumbnail={true}
            previewHeight={50}
            previewWidth={50}
            tooltip={"Add additional Media"}
            uploadIcon={{
              type: "Component",
              icon: AddToPhotosIcon,
              style: { height: 40, width: 40 },
            }}
            handleRemoveUploadedDoc={handleRemoveUploadedDoc}
            onUpload={onAdditionalMediaUpload}
            // onUpload={onAdditionalMediaUpload}
          />
        ) : null}
      </Paper>
    </Grid>
  );
};

export default DetailHeader;

/*
const CategorySubCollectionLine = ({ isEditable, name, label, model }) => {
  const { setValue, watch } = useFormContext();
  const values = watch();
  const [originalOptions, setOriginalOptions] = useState([]);
  // const [createData, setCreateData] = useState({});

  return (
    <>
      {/* <Dialog open={createData?.model} onClose={() => setCreateData({})}>
        <DialogTitle>Create {label}</DialogTitle>
        <DialogContent>
          <TextField
            value={createData?.newData}
            onChange={(e) =>
              setCreateData({ ...createData, newData: e.target.value })
            }
          />

          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              if (createData?.newData?.toString().trim()) {
                try {
                  const data = { ...createData };
                  await createRecord(data.model, { name: data.newData });

                  setCreateData({});
                } catch (err) {
                  console.log(err);
                }
              }
            }}
          >
            Create
          </Button>
        </DialogContent>
      </Dialog> }
      {isEditable && values?.frontEnd?.newDesign ? (
        <RHAsyncAutoComplete
          name={name}
          label={label}
          getOptionLabel={(option) => option?.name || ""}
          freeSolo={true}
          
          setOriginalOptions={setOriginalOptions}
          onChange={(_, newValue) => {
            let value = {};
            const newData =
              typeof newValue === "string"
                ? newValue
                : newValue?.inputValue || newValue?.name;

            const existing = originalOptions.find(
              (opt) =>
                opt?.name?.toString().toLowerCase() ===
                newData?.toString().toLowerCase()
            );

            if (existing) {
              value = existing;

              setValue(name, value);
            } else {
              if (newData) setCreateData({ model, newData });
            }
          }}
          selectOnFocus={true}
          clearOnBlur={true}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.name
            );

            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                name: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          apiUrl={model + "/search"}
          apiBody={{
            filterColumns: {
              name: 1,
            },
          }}
          dynamicAPIbody={true}
          apiMethod="post"
        />
      ) : (
        <Label
          label={label}
          value={getNestedObjValue(values, `${name}.name`) || ""}
        />
      )}
    </>
  );
};

*/
